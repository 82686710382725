var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-app-bar',{staticClass:"principal-menu elevation-0 white-glass",class:`${
			_vm.changeMenu && !_vm.breakpoint.mobile
				? 'white-glass elevation-6 animate__animated animate__faster animate__fadeIn'
				: ''
		} ${
			_vm.nameRoute != 'HomeVersionOne' && !_vm.breakpoint.mobile ? 'white-glass' : ''
		} ${_vm.breakpoint.mobile ? 'mw-purple darken-4 ' : ''}`,attrs:{"app":"","fixed":"","dark":"","color":"transparent"}},[(!_vm.breakpoint.mobile)?_c('v-img',{staticClass:"logo shrink animate__animated animate__fadeInDown animate__faster mx-auto",attrs:{"alt":_vm.meta.app_name,"contain":"","width":"360","src":"/assets/images/logos/cancilleria.webp","lazy-src":"/assets/images/logos/cancilleria.webp"}}):_vm._e(),(_vm.breakpoint.mobile)?_c('v-img',{staticClass:"logo shrink animate__animated animate__fadeInDown animate__faster mx-auto",attrs:{"alt":_vm.meta.app_name,"contain":"","width":"150","src":"/assets/images/logos/sre.webp","lazy-src":"/assets/images/logos/cancilleria.webp"}}):_vm._e(),_c('v-spacer'),(_vm.breakpoint.lgAndUp)?_c('BreakpointXl'):_vm._e(),(_vm.breakpoint.mdOnly || _vm.breakpoint.smOnly)?_c('BreakpointMd'):_vm._e(),(_vm.breakpoint.xsOnly)?_c('BreakpointXs'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }