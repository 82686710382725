import http from "@/services/http-common-rgmx";
let token_api_rgmx = process.env.VUE_APP_TOKEN_API_RGMX;

class wsApiRgmx {
	/* Regiones */

	getRegionsMap() {
		return http.get(
			`/regions/?sort[1]=name:asc&fields[0]=name&fields[1]=slug&fields[2]=summary&populate[location][fields][3]=*&populate[contacto][fields][4]=*`, // ?populate[chapters][fields][0]=title&populate[chapters][fields][1]=slug
			{
				headers: { Authorization: "Bearer " + token_api_rgmx },
			}
		);
	}

	/* Capitulos */

	getChaptersMap() {
		return http.get(
			`/chapters/?sort[1]=title:asc&fields[0]=title&fields[1]=slug&fields[2]=summary&populate[geolocation][fields][3]=*&populate[region][fields][4]=name&populate[region][fields][5]=colorPin&populate[imageCover][fields][6]=url&populate[contacto][fields][7]=*`, //
			{
				headers: { Authorization: "Bearer " + token_api_rgmx },
			}
		);
	}

	/* Nodos */

	getNodesMap() {
		return http.get(
			`/nodes/?sort[1]=title:asc&fields[0]=title&fields[1]=slug&fields[2]=summary&populate[geolocation][fields][3]=*&populate[Contacto][fields][4]=*&populate[social_media][fields][4]=*&populate[imageCover][fields][6]=url`,
			{
				headers: { Authorization: "Bearer " + token_api_rgmx },
			}
		);
	}
}

export default new wsApiRgmx();
