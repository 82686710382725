<template>
	<v-footer
		dark
		padless
		class="rojo red- darken-4 pt-6"
		style="
			background-image: url('/assets/images/texturas/textura-roja.png');
			background-position: center right;
			background-repeat: no-repeat;
			background-size: cover;
		">
		<v-container>
			<v-row class="white--text">
				<v-col cols md="4" class="text-left">
					<h4 class="block white--text text-uppercase mb-4">
						Ligas de Interés
					</h4>
					<ul class="no-list">
						<li>
							<a href="https://www.gob.mx/sre" class="red--text text--lighten-3"
								>Secretaría de Relaciones Exteriores</a
							>
						</li>
						<li>
							<a href="https://ime.gob.mx/" class="red--text text--lighten-3"
								>Instituto de los Mexicanos en el Exterior</a
							>
						</li>
						<li>
							<a
								href="https://www.gob.mx/inmujeres"
								class="red--text text--lighten-3"
								>InMujeres</a
							>
						</li>
						<li>
							<a
								href="https://www.gob.mx/tramites/"
								class="red--text text--lighten-3"
								>Catalogo Nacional de Trámites y Servicios</a
							>
						</li>
					</ul>
				</v-col>
				<v-col cols md="4" class="text-center pa-6">
					<h4 class="block white--text text-uppercase mb-4">
						Redes Sociales de SRE
					</h4>
					<v-btn
						v-for="icon in icons"
						:key="icon"
						class="mx-4 white--text"
						x-large
						icon>
						<v-icon size="56px">
							{{ icon }}
						</v-icon>
					</v-btn>
				</v-col>
				<v-col cols md="4" class="text-center">
					<h4 class="block white--text text-uppercase mb-4">
						Redes Sociales del IME
					</h4>
					<v-btn
						v-for="icon in icons"
						:key="icon"
						class="mx-4 white--text"
						x-large
						icon>
						<v-icon size="56px">
							{{ icon }}
						</v-icon>
					</v-btn>
				</v-col>
			</v-row>

			<v-row class="white--text text-center">
				<v-col cols md="12">
					<v-img
						alt="GobMX"
						class="shrink animate__animated animate__fadeInDown animate__faster"
						height="50"
						contain
						lazy-src="/assets/images/logos/gobmx.svg"
						src="/assets/images/logos/gobmx.svg" />
				</v-col>
			</v-row>

			<!-- <v-row class="pink darken-3">
				<v-col cols md="12" class="white--text text-center">
					{{ new Date().getFullYear() }} — Secretaría de Relaciones Exteriores,
					<strong>Instituto de los Mexicanos en el Exterior</strong>
				</v-col>
			</v-row> -->
		</v-container>
		<!-- <div class="rojo-obscuro" style="">
			{{ new Date().getFullYear() }} — Secretaría de Relaciones Exteriores,
			<strong>Instituto de los Mexicanos en el Exterior</strong>
		</div> -->
	</v-footer>
</template>
<script>
export default {
	name: "FooterTemplate",

	data: () => ({
		icons: [
			"mdi-facebook",
			"mdi-twitter",
			"mdi-linkedin",
			"mdi-instagram",
			"mdi-tiktok",
		],
	}),
};
</script>
