<template>
	<div>
		<v-btn
			small
			tile
			fab
			color="mw-purple darken-4"
			class="ma-1 text--capitalize elevation-0"
			to="/">
			<v-icon>mdi-home</v-icon>
		</v-btn>

		<v-btn
			small
			tile
			fab
			color="mw-purple darken-2"
			class="ma-1 text--capitalize elevation-0"
			to="/programas/pef">
			<v-icon>mdi-human-female-girl</v-icon>
		</v-btn>

		<v-btn
			small
			tile
			fab
			class="mw-purple darken-1 ma-1 text--capitalize elevation-0"
			to="/descargas">
			<v-icon>mdi-hand-heart-outline</v-icon>
		</v-btn>

		<v-btn
			small
			tile
			fab
			color="mw-pink darken-2"
			class="ma-1 text--capitalize elevation-0"
			href="/articulo/mexicanas-en-retorno">
			<v-icon>mdi-face-woman-outline</v-icon>
		</v-btn>

		<v-btn
			small
			tile
			fab
			color="mw-purple darken-3"
			class="ma-1 text--capitalize elevation-0"
			to="/articulo/estudios-sobre-genero-y-migracion">
			<v-icon>mdi-book-open-variant</v-icon>
		</v-btn>
	</div>
</template>

<script>
export default {
	name: "BreakpointMdTemplate",

	components: {},

	data: () => ({
		meta: {
			app_name: process.env.VUE_APP_NAME,
			app_version: process.env.VUE_APP_VERSION,
			base_url: process.env.VUE_APP_BASE_URL,
			endpoint: process.env.VUE_APP_ENDPOINT,
			storage_files: process.env.VUE_APP_STORAGE_FILES,
		},
	}),

	computed: {},

	mounted() {},

	methods: {},
};
</script>
