<template>
	<div>
		<v-app-bar
			app
			fixed
			dark
			color="transparent"
			class="principal-menu elevation-0 white-glass"
			:class="`${
				changeMenu && !breakpoint.mobile
					? 'white-glass elevation-6 animate__animated animate__faster animate__fadeIn'
					: ''
			} ${
				nameRoute != 'HomeVersionOne' && !breakpoint.mobile ? 'white-glass' : ''
			} ${breakpoint.mobile ? 'mw-purple darken-4 ' : ''}`">
			<!-- <h1
				v-if="
					(changeMenu && !breakpoint.mobile) ||
					(nameRoute != 'HomeVersionOne' && !breakpoint.mobile)
				"
				class="text-uppercase text-h6 animate__animated animate__fadeInDown">

				<span class="font-weight-black">Mexicanas</span>
				en el mundo
				<span class="font-weight-regular blue-grey--text">
					{{ meta.app_version }}
				</span>
			</h1> -->

			<!-- <v-img
				v-if="
					(changeMenu && !breakpoint.mobile) ||
					(nameRoute != 'HomeVersionOne' && !breakpoint.mobile)
				"
				:alt="app_name"
				class="logo shrink animate__animated animate__fadeInDown animate__faster mx-auto"
				contain
				width="150"
				src="/assets/images/logos/sre.webp"
				lazy-src="/assets/images/logos/cancilleria.webp" /> -->

			<v-img
				v-if="!breakpoint.mobile"
				:alt="meta.app_name"
				class="logo shrink animate__animated animate__fadeInDown animate__faster mx-auto"
				contain
				width="360"
				src="/assets/images/logos/cancilleria.webp"
				lazy-src="/assets/images/logos/cancilleria.webp" />

			<v-img
				v-if="breakpoint.mobile"
				:alt="meta.app_name"
				class="logo shrink animate__animated animate__fadeInDown animate__faster mx-auto"
				contain
				width="150"
				src="/assets/images/logos/sre.webp"
				lazy-src="/assets/images/logos/cancilleria.webp" />

			<!-- <h1
				v-if="breakpoint.mobile"
				class="text-h6 white--text medium d-inline-block text-truncate">
				Mexicanas en el Mundo
			</h1> -->

			<v-spacer></v-spacer>

			<BreakpointXl v-if="breakpoint.lgAndUp"></BreakpointXl>

			<BreakpointMd
				v-if="breakpoint.mdOnly || breakpoint.smOnly"></BreakpointMd>

			<BreakpointXs v-if="breakpoint.xsOnly"></BreakpointXs>
		</v-app-bar>
		<!-- v-if="changeMenu" -->
	</div>
</template>

<script>
import NavigationTopTemplate from "@/components/template/NavigationTop.vue";
import BreakpointXl from "./breakpoint-xl.vue";
import BreakpointMd from "./breakpoint-md.vue";
import BreakpointXs from "./breakpoint-xs.vue";

export default {
	name: "HeaderTemplate",

	components: {
		NavigationTopTemplate,
		BreakpointXl,
		BreakpointMd,
		BreakpointXs,
	},

	data: () => ({
		changeMenu: false,
		drawer: null,

		meta: {
			app_name: process.env.VUE_APP_NAME,
			app_version: process.env.VUE_APP_VERSION,
			base_url: process.env.VUE_APP_BASE_URL,
			endpoint: process.env.VUE_APP_ENDPOINT,
			storage_files: process.env.VUE_APP_STORAGE_FILES,
		},
	}),

	computed: {
		nameRoute: function () {
			return this.$route.name;
		},
		breakpoint: function () {
			return this.$vuetify.breakpoint;
		},
	},

	mounted() {
		window.addEventListener("scroll", this.onScroll);
		//console.log("🚀 ~ mounted ~ this.nameRoute:", this.nameRoute);
		//this.$vuetify.breakpoint.name
		console.log(
			"🚀 ~ mounted ~ this.$vuetify.breakpoint.name:",
			this.$vuetify.breakpoint.name
		);
	},

	beforeDestroy() {
		window.removeEventListener("scroll", this.onScroll);
	},

	methods: {
		onScroll(e) {
			this.windowTop = e.target.documentElement.scrollTop;
			if (this.windowTop >= 280) {
				this.changeMenu = true;
			} else {
				this.changeMenu = false;
			}
		},
	},
};
</script>
