<template>
	<div>
		<!-- <v-btn small tile fab color="pink"to="/">
			<v-icon>mdi-menu</v-icon>
		</v-btn> -->

		<v-menu offset-y>
			<template v-slot:activator="{ on, attrs }">
				<v-btn
					fab
					tile
					small
					dark
					color="mw-pink darken-2"
					class="elevation-0"
					v-bind="attrs"
					v-on="on">
					<v-icon>mdi-menu</v-icon>
				</v-btn>
			</template>
			<v-list>
				<v-list-item
					v-for="(item, index) in items"
					:key="index"
					:href="`${item.link}`"
					:target="`${item.newWindow ? '_blank' : '_self'}`">
					<v-list-item-title>{{ item.title }}</v-list-item-title>
				</v-list-item>
			</v-list>
		</v-menu>
	</div>
</template>

<script>
export default {
	name: "BreakpointXsTemplate",

	components: {},

	data: () => ({
		items: [
			{ title: "Inicio", link: "/", newWindow: false },
			{
				title: "Politica Exterior Feminista",
				link: "/programas/pef",
				newWindow: false,
			},
			{
				title: "Apoyos en el Exterior",
				link: "/apoyos-en-el-exterior",
				newWindow: false,
			},
			{
				title: "Mexicanas en Retorno",
				link: "https://ime.gob.mx/familiasenretorno",
				newWindow: true,
			},
			{
				title: "Estudios sobre Género",
				link: "/articulo/estudios-sobre-genero-y-migracion",
				newWindow: false,
			},
		],
		meta: {
			app_name: process.env.VUE_APP_NAME,
			app_version: process.env.VUE_APP_VERSION,
			base_url: process.env.VUE_APP_BASE_URL,
			endpoint: process.env.VUE_APP_ENDPOINT,
			storage_files: process.env.VUE_APP_STORAGE_FILES,
		},
	}),

	computed: {},

	mounted() {},

	methods: {},
};
</script>
