import Vue from "vue";
import VueRouter from "vue-router";
//import HomeVersionOneComponent from "@/components/HomePage/VersionOne.vue";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "HomeVersionOne",
		//component: HomeVersionOneComponent,
		component: () =>
			import(
				/* webpackChunkName: "homepage" */ "@/components/HomePage/VersionOne.vue"
			),
	},

	/*
	 * !Static
	 */
	// {
	// 	path: "/static",
	// 	name: "StaticPages",
	// 	component: () =>
	// 		import(/* webpackChunkName: "static-pages" */ "@/static/Index.vue"),
	// },
	// {
	// 	path: "/static/mexicanas-que-inspiran",
	// 	name: "StaticBiographyQuienes",
	// 	component: () =>
	// 		import(/* webpackChunkName: "static-pages" */ "@/static/biography.vue"),
	// },
	// {
	// 	path: "/static/ofertas-gobmx",
	// 	name: "StaticCardsGobmx",
	// 	component: () =>
	// 		import(/* webpackChunkName: "static-pages" */ "@/static/cards.vue"),
	// },
	// {
	// 	path: "/static/ofertas-instituciones",
	// 	name: "StaticCardsInstituciones",
	// 	component: () =>
	// 		import(/* webpackChunkName: "static-pages" */ "@/static/cards.vue"),
	// },
	// {
	// 	path: "/static/ofertas-organizaciones",
	// 	name: "StaticCardsOrganizaciones",
	// 	component: () =>
	// 		import(/* webpackChunkName: "static-pages" */ "@/static/cards.vue"),
	// },
	// {
	// 	path: "/static/empleo",
	// 	name: "StaticCardsEmpleo",
	// 	component: () =>
	// 		import(/* webpackChunkName: "static-pages" */ "@/static/cards.vue"),
	// },

	// {
	// 	path: "/static/educacion",
	// 	name: "StaticMIcrositeEducacion",
	// 	component: () =>
	// 		import(/* webpackChunkName: "static-pages" */ "@/static/microsite.vue"),
	// },
	// {
	// 	path: "/static/salud",
	// 	name: "StaticMIcrositeSalud",
	// 	component: () =>
	// 		import(/* webpackChunkName: "static-pages" */ "@/static/microsite.vue"),
	// },
	// {
	// 	path: "/static/violencia-genero",
	// 	name: "StaticMIcrositeViolencia",
	// 	component: () =>
	// 		import(/* webpackChunkName: "static-pages" */ "@/static/microsite.vue"),
	// },
	// {
	// 	path: "/static/:slug",
	// 	name: "StaticPagesQuienes",
	// 	component: () =>
	// 		import(/* webpackChunkName: "static-pages" */ "@/static/post.vue"),
	// },
	/***/

	/*
	 * Categorias
	 */
	{
		path: "/programas/pef",
		name: "ProgramasPef",
		component: () =>
			import(
				/* webpackChunkName: "actions-and-programs" */ "@/components/FeministPolitics/Timeline.vue"
			),
	},
	{
		path: "/programas/:category",
		name: "Programas",
		component: () =>
			import(
				/* webpackChunkName: "actions-and-programs" */ "@/components/ActionsPrograms/Blog.vue"
			),
	},

	{
		path: "/mexicanas-que-inspiran/:slug",
		name: "MexicanasQueInspiranItem",
		component: () =>
			import(
				/* webpackChunkName: "mexicanas-que-inspiran" */ "@/components/MexicansInspire/People.vue"
			),
	},

	{
		path: "/mexicanas-que-inspiran",
		name: "MexicanasQueInspiran",
		component: () =>
			import(
				/* webpackChunkName: "mexicanas-que-inspiran" */ "@/components/MexicansInspire/ArticlesList.vue"
			),
	},

	// {
	// 	path: "/ofertas-internacionales",
	// 	name: "OfertasInternacionales",
	// 	component: () =>
	// 		import(
	// 			/* webpackChunkName: "ofertas-internacionales" */ "@/static/cards.vue"
	// 		),
	// },

	{
		path: "/apoyos-en-el-exterior",
		name: "ApoyosExterior",
		component: () =>
			import(
				/* webpackChunkName: "apoyos-exterior" */ "@/components/SupportAbroad/Landing.vue"
			),
	},

	{
		path: "/directorio-vaim",
		name: "ApoyosExterior",
		component: () =>
			import(
				/* webpackChunkName: "directorio-vaim" */ "@/components/SupportAbroad/Directory.vue"
			),
	},

	{
		path: "/atencion-prioritaria/:slug",
		name: "AtencionPrioritaria",
		component: () =>
			import(
				/* webpackChunkName: "atencion-prioritaria" */ "@/static/post.vue"
			),
	},

	{
		path: "/articulo/:slug",
		name: "ArticlesComponent",
		component: () =>
			import(
				/* webpackChunkName: "articulos" */ "@/components/Articles/Article.vue"
			),
	},

	{
		path: "/descargas",
		name: "Descargas",
		component: () =>
			import(
				/* webpackChunkName: "descargas" */ "@/components/Downloads/List.vue"
			),
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

export default router;
