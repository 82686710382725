<template>
	<div>
		<v-btn
			tile
			color="mw-purple darken-4"
			class="ma-1 text--capitalize elevation-0"
			href="/">
			Inicio
		</v-btn>

		<v-btn
			tile
			color="mw-purple darken-2"
			class="ma-1 text--capitalize elevation-0"
			href="/programas/pef">
			Politica Exterior Feminista
		</v-btn>

		<!-- <v-btn
			tile
			class="mw-purple darken-1 ma-1 text--capitalize elevation-0"
			href="/apoyos-en-el-exterior">
			Apoyos en el Exterior
		</v-btn> -->

		<v-menu open-on-hover offset-y>
			<template v-slot:activator="{ on, attrs }">
				<v-btn
					tile
					class="mw-purple darken-1 ma-1 text--capitalize elevation-0"
					dark
					v-bind="attrs"
					v-on="on"
					href="/apoyos-en-el-exterior">
					Apoyos en el Exterior
					<v-icon small right>mdi-chevron-down</v-icon>
				</v-btn>
			</template>
			<v-list>
				<v-list-item
					dense
					v-for="(item, index) in items"
					:key="index"
					:href="`${item.link}`"
					:target="`${item.target}`">
					<v-list-item-title class="">{{ item.title }}</v-list-item-title>
				</v-list-item>
			</v-list>
		</v-menu>

		<v-btn
			tile
			color="mw-pink darken-2"
			class="ma-1 text--capitalize elevation-0"
			href="/articulo/mexicanas-en-retorno">
			Mexicanas en Retorno
		</v-btn>

		<v-btn
			tile
			color="mw-purple darken-3"
			class="ma-1 text--capitalize elevation-0"
			href="/articulo/estudios-sobre-genero-y-migracion">
			Estudios sobre Género
		</v-btn>
	</div>
</template>

<script>
export default {
	name: "BreakpointXlTemplate",

	components: {},

	data: () => ({
		items: [
			{
				title: "Mexicana Emprende",
				link: "https://ime.gob.mx/vaf/programa/pceme",
				target: "_blank",
			},
			{
				title: "Vinculación y Atención Integral para la Mujer ",
				link: "/articulo/vaim",
				target: "_self",
			},
			{
				title: "Salud mental y atención psicológica ",
				link: "https://ime.gob.mx/salud/programa/salud-mental",
				target: "_blank",
			},
			{
				title: "Educación",
				link: "https://ime.gob.mx/educacion",
				target: "_blank",
			},
			{
				title: "Directorio VAIM",
				link: "/directorio-vaim",
				target: "_self",
			},
			{
				title: "Directorio Mexicana Emprende",
				link: "https://ime.gob.mx/pceme",
				target: "_blank",
			},
		],
		meta: {
			app_name: process.env.VUE_APP_NAME,
			app_version: process.env.VUE_APP_VERSION,
			base_url: process.env.VUE_APP_BASE_URL,
			endpoint: process.env.VUE_APP_ENDPOINT,
			storage_files: process.env.VUE_APP_STORAGE_FILES,
		},
	}),

	computed: {},

	mounted() {},

	methods: {},
};
</script>
